<template>
  <div id='search'>
    <div class="nav">
      <!-- 跨境好服务    臻品永不停 -->
      <van-search @search="onSearch" v-model="value" :placeholder="`${language.l_1} ${language.l_2}`" shape="round" show-action>
        <!-- 搜索 -->
        <template v-slot:action><p @click="onSearch" @keydown="onSearch">{{language.l_229}}</p></template>
      </van-search>
    </div>
    <div v-if="historyList" class="searchList">
      <div class="searchList-title">
        <!-- 搜索历史 -->
        <span class="searchList-text">{{language.l_230}}</span>
        <span @click="clearHistory" class="iconfont icon_close"></span>
      </div>
      <div class="searchList-content" ref="content">
        <div v-for="(item, index) in historyList" :key="index" @click="toSearchInfo(item)" ref="item"class="searchList-item">{{item}}</div>
      </div>
    </div>
    <div v-if="keyWordsList" class="hotProduct">
      <!-- 热门搜索 -->
      <div class="hotProduct-title">{{language.l_231}}</div>
      <div class="hotProduct-content">
        <div v-for="(item, index) in keyWordsList" :key="index" @click="toSearchInfo(item.name)" class="hotProduct-item van-hairline--surround">
          <span class="iconfont icon_hot">{{item.name}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getKeywords} from '@api' 

export default {
  data () {
    return {
      value: '',
      historyList: null,  //搜搜历史
      keyWordsList: null,  //热门搜索
      language: this.$store.getters.language
    }
  },
  activated() {
    if(!this.$route.meta.isUseCache) {
      this._resetData()
      getKeywords().then(res => {
        if(res.data.length == 0) {
          this.keyWordsList = null
        }else {
        this.keyWordsList = res.data
        }
      })
      this.getHistory();
    }
    this.$route.meta.isUseCache = false;
  },
  methods:{
    _resetData() {
      this.value = '',
      this.historyList = null;
      this.keyWordsList = null;
    },
    getHistory() {  //获取搜索记录
      this.historyList = JSON.parse(localStorage.getItem('historyList'))
    },
    onSearch() {  //搜索
      if(this.value) {
        this.$router.push({
          name: 'SearchInfo',
          query: {keywords: this.value}
        })
        return;
      }
      this.$toast(this.language.l_232)  //请输入搜索内容
    },
    clearHistory() {  //清除历史记录
      this.$dialog.confirm({
        message: this.language.l_233,  //是否删除全部历史搜索
        cancelButtonText: this.language.l_22,  //我再想想
        confirmButtonText: this.language.l_14  //刪除
      }).then(() => {
        localStorage.removeItem('historyList');
        this.historyList = null;
        this.$toast.success(this.language.l_56)  //删除成功
      }).catch(() => this.$toast.fail(this.language.l_313))  //取消删除
    },
    toSearchInfo(keywords) {  //跳转搜索详情
      this.$router.push({
        name: 'SearchInfo',
        query: {keywords}
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    if(to.name == 'SearchInfo') {from.meta.isUseCache = true}
    next();
  }
}
</script>

<style lang='less' scoped>
#search {
  height: 100%;
  background-color: #fff;
  .list {
    padding: 10px;
    background-color: #fff;
  }
  .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .item {
    display: inline-block;
    box-sizing: border-box;
    font-size: 12px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .searchList {
    .list;
    .searchList-title {
      .title;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .searchList-text {
        font-weight: bold;
      }
      .icon_close {
        font-size: 16px;
        font-weight: normal;
      }
    }
    .searchList-content {
      display: flex;
      flex-wrap: wrap;
      &.searchList-content-unfold {
        div {
          &:nth-of-type(1) {
            max-width: calc(100% - 26px);
          }
        }
      }
      .searchList-item {
        .item;
        max-width: 100%;
        height: 26px;
        line-height: 26px;
        padding: 0 10px;
        color: #ccc;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        background-color: #f8f8f8;
        border-radius: 25px;
      }
      .searchList-icon {
        width: 26px;
        height: 26px;
        line-height: 24px;
        text-align: center;
        background-color: #f8f8f8;
        border-radius: 50%;
        &.icontop {
          color: #ccc;
          transform: rotate(180deg);
        }
      }
    }
  }
  .hotProduct {
    .list;
    margin-top: 5px;
    .hotProduct-title {
      .title;
    }
    .hotProduct-content {
      .hotProduct-item {
        .item;
        &.van-hairline--surround {
          &::after {
            border-radius: 30px;
          }
        }
        .icon_hot {
          font-size: 12px;
          &::before {
            color: red;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
</style>